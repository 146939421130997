<template>
	<div class="v-data-table bt-table theme--light">
		<template v-if="pageLoading">
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>

		<div v-else class="v-data-table bt-table theme--light">
			<div class="v-data-table__wrapper table_height" ref="btt_height">
				<table class="w-100">
					<thead>
						<tr>
							<th
								v-for="(th, th_idx) in allowedSortedThead"
								:key="th.key + '_' + th_idx"
								:width="th.width"
							>
								<template v-if="th.checkbox">
									<v-checkbox
										dense
										v-model="checkAll"
										:ripple="false"
										hide-details
										color="blue darken-4"
										class="m-0 p-0"
									></v-checkbox>
								</template>
								<template v-else>
									{{ th.name }}
								</template>
							</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="tbody.length">
							<tr
								v-for="(td, td_idx) in tbody"
								:key="'domain_' + td.id + '_' + td_idx"
								:class="getAciveNotes(td.id)"
							>
								<template v-for="(th, th_idx) in allowedSortedThead">
									<td
										:key="'domain_data_$_' + th.key + '_' + th_idx"
										:width="th.width"
										v-on:click="routeToDetail(td.id)"
									>
										<template v-if="th.checkbox">
											<v-checkbox
												dense
												:ripple="false"
												:value="td.id"
												hide-details
												v-model="selectedRows"
												multiple
												@change="selectRow"
												class="m-0 p-0"
												color="blue darken-4"
											></v-checkbox>
										</template>

										<template v-else-if="th.key == 'action'">
											<v-menu offset-y left max-width="150">
												<template v-slot:activator="{ on, attrs }">
													<v-btn depressed fab x-small color="default" v-bind="attrs" v-on="on">
														<v-icon>mdi-dots-vertical</v-icon>
													</v-btn>
												</template>
												<v-list dense>
													<v-list-item
														v-for="(item, index) in actionList"
														:key="index"
														@click="openAction(item, td.id)"
													>
														<v-list-item-title class="cursor-pointer"
															><v-icon small class="me-2">{{ item.icon }}</v-icon
															>{{ item.title }}</v-list-item-title
														>
													</v-list-item>
												</v-list>
											</v-menu>
										</template>

										<template v-else-if="th.key == 'company_uen'">
											<div class="">
												<div class="d-flex justify-space-between">
													<div v-if="td.company_uen">
														{{ td.company_uen }}
													</div>
													<div v-else>
														<em class="text-muted">no Uen</em>
													</div>
												</div>
											</div>
										</template>

										<template v-else-if="th.key == 'email'">
											<div class="">
												<div class="d-flex justify-space-between">
													<div v-if="td.email">
														<v-icon class="me-1" small>mdi-email</v-icon
														><span
															@click.stop
															class="cursor-pointer"
															v-on:click="copyNumber(td.email, 'Email')"
															>{{ td.email }}</span
														>
													</div>
													<div v-else>
														<em class="text-muted">no Email</em>
													</div>
												</div>
											</div>
										</template>
										<template v-else-if="th.key == 'website'">
											<div class="">
												<div class="d-flex justify-space-between">
													<div v-if="td.website">
														<v-icon class="me-1" small>mdi-domain</v-icon
														><span @click.stop class="cursor-pointer" v-on:click="copyNumber(td.website, 'website')">{{
															td.website
														}}</span>
													</div>
													<div v-else>
														<em class="text-muted">no website</em>
													</div>
												</div>
											</div>
										</template>

										<template v-else-if="th.key == 'phone'">
											<div class="">
												<div class="d-flex justify-space-between">
													<div v-if="td.phone">
														<v-icon small>mdi-phone</v-icon
														><span
															@click.stop
															class="cursor-pointer"
															v-on:click="copyNumber(td.phone, 'phone')"
															>{{ td.phone }}</span
														>
													</div>
													<div v-else>
														<em class="text-muted">no mobile</em>
													</div>
												</div>
											</div>
										</template>

										<template v-else-if="th.key == 'barcode'">
											<div>
												<v-chip
													class="white--text"
													label
													:color="td.type == 'bthurst' ? 'orange' : 'teal lighten-1'"
													>{{ td.barcode }}
												</v-chip>
											</div>
										</template>

										<template v-else-if="th.key == 'name'">
											<div class="d-flex">
												<v-icon class="me-1" small>mdi-domain</v-icon>
												<ShowValue :object="td" object-key="company_name" label="Company Name"></ShowValue>
											</div>
										</template>
										<template v-else-if="th.key == 'contact'">
											<v-row align="center">
												<v-col class="d-flex align-center" cols="auto">
													<v-avatar
														class="me-2"
														color="primary"
														size="30"
														v-if="td?.singlecontact?.contact_name"
													>
														<span class="text-white">{{ getInitials(td?.singlecontact?.contact_name) }}</span>
													</v-avatar>
													<span class="me-2">{{ td?.singlecontact?.salutation }} </span>
													<ShowValue
														:object="td.singlecontact"
														object-key="contact_name"
														label="Contact"
													></ShowValue>
												</v-col>
											</v-row>
										</template>

										<template v-else-if="th.key == 'added_at'">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-chip v-bind="attrs" v-on="on" label outlined color="green" text-color="green">{{
														td.added_at
													}}</v-chip>
												</template>
												<span>Created date</span>
											</v-tooltip>
										</template>
										<template v-else-if="th.key == 'updated_at'">
											<div v-if="td.updated_at != 0">
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-chip v-bind="attrs" v-on="on" label outlined color="green" text-color="green">{{
															formatTime2(td.updated_at)
														}}</v-chip>
													</template>
													<span>Updated At</span>
												</v-tooltip>
											</div>
											<div v-else>
												<em class="text-muted">no updated</em>
											</div>
										</template>

										<template v-else-if="th.key == 'added_by'">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-chip v-bind="attrs" v-on="on" pill outlined>
														<v-avatar left>
															<v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg" alt="John"></v-img>
														</v-avatar>
														<span class="text-capitalize">{{ td?.added_by?.display_name }}</span>
													</v-chip>
												</template>
												<span>Created by</span>
											</v-tooltip>
										</template>

										<template v-else-if="th.key == 'tag'">
											<div class="d-flex align-items-center flex-wrap" v-if="td.tag?.length > 0">
												<div
													v-for="(num, index) in td.tag.slice(0, 2)"
													:key="index"
													class="d-flex align-items-center me-2 mb-1"
												>
													<v-chip size="small" variant="outlined" text-color="white" :color="num.color">
														{{ num.text }}
													</v-chip>
												</div>
												<template v-if="td.tag?.length > 2">
													<span class="ms-1 text-blue">+{{ td.tag?.length - 2 }} more..</span>
												</template>
											</div>
											<template v-else>
												<ShowValue :object="td" object-key="tags" label="tag"></ShowValue>
											</template>
										</template>

										<template v-else-if="th.key == 'status'">
											<div @click.stop class="d-flex justify-space-between align-center"></div>
										</template>

										<template v-else-if="th.key == 'last_update'">
											<Chip class="mr-1" outlined :text="td.updated_at" textColor="green" color="green"></Chip>
										</template>
									</td>
								</template>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td :colspan="8">
									<p class="m-0 row-not-found text-center py-3">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There <span class="text-lowercase">are no company at the moment.</span>
									</p>
								</td>
							</tr>
						</template>
					</tbody>
					<tfoot>
						<tr>
							<td
								colspan="8"
								v-if="tbody.length > 0"
								style="position: absolute; width: 100%; bottom: 10px; background: #fff"
							>
								<v-row class="listing-footer border-top">
									<v-col class="my-auto h6 text-capitalize"
										><b>{{ showingString }}</b>
									</v-col>
									<v-col class="my-auto">
										<v-pagination v-model="currentPage" :length="totalPage" v-on:input="updatePagination">
										</v-pagination
									></v-col>
								</v-row>
							</td>
						</tr>
					</tfoot>
					<tfoot v-if="false">
						<tr>
							<td colspan="8" style="position: absolute; width: 100%; bottom: 10px; background: #fff">
								<v-row class="listing-footer">
									<v-col class="h6 mb-0 text-capitalize"
										><b> {{ showingString }}</b>
									</v-col>
									<v-col class="">
										<v-pagination
											color="blue darken-4"
											v-model="currentPage"
											:length="totalPage"
											total-visible="9"
											class="listing-pagination"
											@input="updatePagination"
										></v-pagination>

										></v-col
									>
								</v-row>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>

		<CreateCompanyDrawer
			v-if="update_comapny"
			:drawer="update_comapny"
			title-status="Update"
			:id="id"
			v-on:createSuccess="getdata()"
			v-on:close="update_comapny = false"
		></CreateCompanyDrawer>
		<DeleteTemplate
			type="Company"
			:deleteUrl="delURL"
			:deleteText="delTEXT"
			:deleteDialog="confirm_dialog"
			v-on:close="confirm_dialog = false"
			v-on:success="
				confirm_dialog = false;
				getdata();
			"
		></DeleteTemplate>
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue";
import { mapGetters } from "vuex";
import SettingsMixin from "@/core/mixins/settings.mixin";

import ListingMixin from "@/core/mixins/listing.mixin";
import { GET_LISTING } from "@/core/services/store/listing.module";

import DeleteTemplate from "@/view/components/DeleteTemplate";
import { SET_CURRENT_PAGE } from "@/core/services/store/listing.module";
import { SET_MESSAGE, SET_NOTE_FLAG } from "@/core/services/store/common.module";
import CreateCompanyDrawer from "@/view/pages/leads/create/CreateCompanyDrawer";

export default {
	name: "CompanyListing",
	mixins: [ListingMixin, SettingsMixin],

	components: {
		ShowValue,
		CreateCompanyDrawer,
		DeleteTemplate,
	},
	props: {
		statusFilterData: {
			type: Array,
		},
	},
	data() {
		return {
			id: 0,
			delURL: null,
			status: "all",

			delTEXT: null,
			showing_string: null,

			// tbody: [
			// 	{
			// 		id: 1,
			// 		company_name: "abc Corp",
			// 		email: "john@gmail.com",
			// 		website: "abcbthrust.com",
			// 		phone: "+65 123456",
			// 		added_at: "1 Months Ago",
			// 	},
			// 	{
			// 		id: 2,
			// 		company_name: "abc Corp",
			// 		website: "abcbthrust.com",
			// 		phone: "+65 123456",
			// 		added_at: "1 Months Ago",
			// 	},
			// ],
			actionList: [
				{ title: "View", value: "view", icon: "mdi-eye" },
				{ title: "Edit", value: "edit", icon: "mdi-pencil" },
				{ title: "Delete", value: "delete", icon: "mdi-delete" },
			],
			confirm_dialog: false,
			create_contact: false,
			update_comapny: false,

			type: null,
			checkbox: false,

			totalRows: 0,
			rowsOffset: 0,
			showingFrom: 0,
			showingTo: 0,
			editTask: {},
			attendieList: {},
		};
	},

	methods: {
		getAciveNotes(id) {
			if (id == this.notesActive) {
				return "bg-orange";
			}
			return "";
		},
		getInitials(name) {
			const names = name.split(" ");
			if (names.length > 1) {
				return names.map((word) => word[0].toUpperCase()).join("");
			} else {
				const firstLetter = names[0][0].toUpperCase();
				return firstLetter + names[0][1].toUpperCase();
			}
		},
		getListingData() {
			this.pageLoading = true;
			const filter = new Object({
				tz: new Date().getTime(),
				page: this.currentPage ? this.currentPage : null,
			});
			if (this.internalType && this.internalValue) {
				filter[this.internalType] = this.internalValue;
			}

			this.$store
				.dispatch(GET_LISTING, { url: "company", filter })
				.then(() => {
					setTimeout(() => {
						this.calculateHeight();
					}, 500);
				})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getFirstTwoLetters(name) {
			if (!name) return "";
			return name.slice(0, 2).toUpperCase();
		},
		getdata() {
			this.$emit("createSuccess", true);
		},
		openAction(action, id) {
			switch (action.value) {
				case "view":
					this.$router.push({ name: "company-detail", params: { id: id } });
					break;
				case "edit":
					this.id = id;
					this.update_comapny = true;
					this.$store.commit(SET_NOTE_FLAG, id);
					break;
				case "delete":
					this.id = id;
					this.delURL = `company/${id}`;
					this.confirm_dialog = true;

					break;
			}
		},
		routeToDetail(id) {
			// this.$store.commit(SET_NOTE_FLAG, id);
			this.$router.push({ name: "company-detail", params: { id: id } });
		},

		updatePagination(param) {
			console.log(this.currentPage); // Logging current page value
			console.log(param); // Logging param value

			if (this.currentPage === param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);

			this.$nextTick(() => {
				this.getListingData(); // Call a method to update filtered rows based on new pagination
			});
		},
		copyNumber(data, type) {
			if (type == "phone") {
				data = data.split("-").pop();
			}
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `${type} <b>${data}</b> - successfully coppied.` },
			]);
		},
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"currentUser",
			"currentPage",
			"totalPage",
			"notesActive",
			"showingString",
			"thead",
			"total",
			"moduleSettings",
		]),
	},
};
</script>

<style scoped>
.bt-table tbody tr:nth-child(even) {
	background-color: rgb(237, 242, 251);
}
</style>
