<template>
	<div class="">
		<div v-if="drawer">
			<v-navigation-drawer
				v-model="drawer"
				fixed
				width="600"
				right
				temporary
				stateless
				style="z-index: 200"
			>
				<div class="drawer-wrapper">
					<v-flex class="py-4 px-5 border-bottom drawer-header">
						<div class="font-level-3-bold">{{ titleStatus }} Company</div>
					</v-flex>
					<v-form
						ref="form"
						id="form"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="update_or_create"
					>
						<div class="drawer-content pt-5 px-5">
							<v-row>
								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500 required">Company Name </label>
								</v-col>
								<v-col md="9" class="mb-0">
									<div class="cust-req">
										<TextValidateInput
											hide-details
											:disabled="pageLoading"
											:hideTopMargin="true"
											:loading="pageLoading"
											:rules="[vrules.required(company.company_name, 'Company Name'),vrules.maxLength(company.company_name, 'Company Email', 30)]"
											:class="{
												required: !company.company_name,
											}"
											id="company-name"
											placeholder="Company Name"
											:validationField="{
												url_type: 'customer',
												filter_type: 'contact',
												field: 'company_name',
											}"
											required
											:parent-id="company.id"
											:current-id="company.id"
											show-dropdown
											v-model="company.company_name"
											v-on:keyup="appendDisplayName()"
										></TextValidateInput>
									</div>
								</v-col>
								<v-col md="3" class="text-end mt-2">
									<label class="fw-500">Email</label>
								</v-col>
								<v-col md="9" class="mb-3">
									<v-text-field
										hide-details
										outlined
										placeholder="Email"
										v-model="company.email"
										:rules="[
											vrules.validEmail(company.email, 'Company Email'),
											vrules.maxLength(company.email, 'Company Email', 30),
										]"
										:disabled="pageLoading"
										:loading="pageLoading"
									>
										<!-- <template v-slot:counter="{ props }">
											<v-counter
												v-bind="props"
												:value="company.email.trim().split(/\s+/).filter(Boolean).length"
											></v-counter>
										</template> -->
									</v-text-field>
									<!-- <span class="text-end">{{ company?.email?.length }}/30</span> -->
									<!-- <TextInput
										dense
										id="mobile"
										hide-details
										type="email"
										placeholder="Email"
										:disabled="pageLoading"
										maxLength="30"
										:rules="[vrules.validEmail(company.email, 'Email Address')]"

										:loading="pageLoading"
										class="mt-0"
										v-model="company.email"
										counter
									></TextInput> -->
								</v-col>
								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">Phone</label>
								</v-col>
								<v-col md="9">
									<PhoneTemplate
										:disabled="pageLoading"
										id="mobile_company"
										class-name="mt-0"
										v-model="company.phone"
										:loading="pageLoading"
										@loading="($event) => (pageLoading = $event)"
										@blur="validatePhone(cindex)"
										@validity="phoneValid($event, cindex, 'phone')"
										@clear="company.phone = null"
									></PhoneTemplate>
								</v-col>

								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">Website</label>
								</v-col>
								<v-col md="9" class="mb-3">
									<TextInput
										dense
										id="website"
										hide-details
										type="text"
										placeholder="Website"
										:disabled="pageLoading"
										:loading="pageLoading"
										class="mt-0"
										v-model="company.website"
										:rules="[vrules.maxLength(company.website, 'Company Email', 25)]"
									></TextInput>
								</v-col>
								<!-- <v-col md="3" class="mt-2 text-end">
								<label class="fw-500">Fax</label>
							</v-col>
							<v-col md="9" class="mb-0">
								<PhoneTemplate
									:disabled="pageLoading"
									id="fax"
									class-name="mt-0"
									v-model="company.fax"
									:loading="pageLoading"
									@loading="($event) => (pageLoading = $event)"
									@blur="validatePhone(cindex)"
									@validity="phoneValid($event, cindex, 'Fax')"
									@clear="company.fax = null"
								></PhoneTemplate>
							</v-col> -->

								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">Contacts</label>
								</v-col>
								<v-col md="9" class="mb-2">
									<div class="d-flex align-center">
										<!-- {{ contactlist }} -->
										<v-autocomplete
											:items="contactlist"
											item-text="contact_name"
											item-value="id"
											depressed
											placeholder="Contact"
											hide-details
											outlined
											hide-selected
											class="cuatom-drop pr-1"
											multiple
											color="blue darken-4"
											small-chips
											deletable-chips
											v-model="company.contact"
										>
											<template #selection="{ item, attrs, selected, select }">
												<v-chip small v-bind="attrs" close @click="select" @click:close="remove(item)">
													<span class="text-blue">{{ item.contact_name }}</span>
													{{ getValue(item, "name") }}
												</v-chip>
											</template>
											<template #item="{ item }">
												<div class="d-flex align-center py-1">
													<v-chip color="blue darken-4"
														><span class="text-white">{{ getInitials(item.contact_name) }}</span></v-chip
													>

													<div class="ml-3 fw-500" :style="{ fontSize: '13px' }">
														<div class="text-uppercase">
															{{ item.contact_name }}
														</div>
														<div class="text-muted"></div>
													</div>
												</div>
											</template>
										</v-autocomplete>
										<v-icon @click="contactAddDialog" class="cursor-pointer" color="blue"
											>mdi-account-plus</v-icon
										>
									</div>
								</v-col>
								<v-row class="mx-0 py-2 mb-2" style="background-color: rgb(237, 242, 251)">
									<v-col md="3" class="mt-3 text-end">
										<label class="fw-500">Country </label>
									</v-col>
									<v-col lg="9" class="my-auto py-0 position-relative">
										<v-autocomplete
											:items="countryList"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Country"
											v-model="company.country"
											v-on:change="countryCheck($event)"
										>
										</v-autocomplete>
										<!-- <ManageAutocomplete title="Country" typeId="5" v-on:update="getSettings">
								</ManageAutocomplete> -->
									</v-col>

									<v-col md="3" class="my-auto text-end">
										<label class="fw-500">Address </label>
									</v-col>
									<v-col md="9" class="mb-3">
										<SearchAddress
											:key="searchKey"
											variant="outlined"
											density="compact"
											hide-details
											:country-code="country_code"
											:country="company.country"
											@update:address="(data) => setAddress(data)"
											label="Address"
											placeholder="Address"
										/>
									</v-col>
									<v-col md="3" class="mt-1 text-end">
										<label class="fw-500">Address Line 1</label>
									</v-col>
									<v-col lg="9" class="my-auto py-0 position-relative mb-3">
										<v-text-field
											hide-details
											outlined
											placeholder="Address Line 1"
											v-model="company.address_line_1"
										>
										</v-text-field>

										<!-- <ManageAutocomplete title="Country" typeId="5" v-on:update="getSettings">
								</ManageAutocomplete> -->
									</v-col>
									<v-col md="3" class="mt-1 text-end">
										<label class="fw-500">Address Line 2</label>
									</v-col>
									<v-col lg="9" class="my-auto py-0 position-relative mb-3">
										<v-text-field
											hide-details
											outlined
											placeholder="Address Line 2"
											v-model="company.address_line_2"
										>
										</v-text-field>

										<!-- <ManageAutocomplete title="Country" typeId="5" v-on:update="getSettings">
								</ManageAutocomplete> -->
									</v-col>
									<v-col md="3" class="mt-1 text-end">
										<label class="fw-500">Postal Code</label>
									</v-col>
									<v-col lg="9" class="my-auto py-0 position-relative mb-3">
										<v-text-field
											hide-details
											outlined
											placeholder="Postal code"
											v-model.trim="company.postal_code"
											:rules="[vrules.maxLength(company.postal_code, 'Postal Code', 10)]"
											v-mask="'######'"
											type="Number"
										>
										</v-text-field>

										<!-- <ManageAutocomplete title="Country" typeId="5" v-on:update="getSettings">
								</ManageAutocomplete> -->
									</v-col>
								</v-row>
								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">Tags</label>
								</v-col>
								<v-col md="9" class="mb-3">
									<TagsAutoSearch type="company" v-model="company.tags" />
								</v-col>
								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">UEN</label>
								</v-col>
								<v-col md="9" class="mb-0 pb-0">
									<div class="cust-req">
										<TextValidateInput
											:hideTopMargin="true"
											:disabled="pageLoading"
											:loading="pageLoading"
											:rules="[vrules.minLength(company.company_uen, 'UEN', 3)]"
											id="company-uen"
											placeholder="UEN"
											:validationField="{
												url_type: 'customer',
												filter_type: 'contact',
												field: 'uen',
											}"
											:parent-id="company.id"
											:current-id="company.id"
											show-dropdown
											v-model="company.company_uen"
											class="error_msg"
										>
										</TextValidateInput>
									</div>
								</v-col>
								<v-col md="3" class="mt-1 text-end">
									<label class="fw-500">Industry</label>
								</v-col>
								<v-col md="9" class="mb-0 pt-0">
									<v-autocomplete
										:items="industrylist"
										item-text="text"
										item-value="value"
										hide-details
										outlined
										placeholder="Industry"
										v-model="company.industry"
										class="pe-8"
									>
									</v-autocomplete>
									<ManageDescriptionDialog
										customClass="mt-0 px-0"
										title="Industry"
										typeId="55"
										v-on:update="getSettings()"
										v-on:create_setting="industryItem"
									>
									</ManageDescriptionDialog>
								</v-col>
								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">Description</label>
								</v-col>
								<v-col md="9" class="">
									<v-checkbox
										class="ma-0"
										hide-details
										color="primary"
										v-model="company.isDescription"
									></v-checkbox>
									<TextAreaInput
										v-if="company.isDescription"
										hide-details
										placeholder="Description"
										v-model="company.description"
										:word-limit="200"
										:rows="5"
									></TextAreaInput>
								</v-col>
							</v-row>
						</div>
					</v-form>
				</div>
				<template v-slot:append>
					<div class="py-3 px-5 d-flex justify-content-end border-top">
						<v-btn depressed tile class="mr-2 cancel_btn" v-on:click="$emit('close', true)">Cancel</v-btn>
						<v-btn
							tile
							depressed
							color="white--text"
							class="blue darken-4 save_btn"
							:loading="pageLoading"
							:disabled="pageLoading || !formValid"
							v-on:click="update_or_create()"
							>Save</v-btn
						>
					</div>
				</template>
			</v-navigation-drawer>
		</div>
		<ContactAddDialog
			v-if="contactDialog"
			v-on:createSuccess="
				contactDialog = false;
				getContact();
			"
			v-on:close="contactDialog = false"
			:contact-dialog="contactDialog"
		>
		</ContactAddDialog>
	</div>
</template>

<script>
import TextInput from "@/view/components/TextInput";
// import TextField from "@/view/components/TextField";

import { POST, GET, QUERY, PUT } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { find } from "lodash";
// import ValidationMixin from "@/core/mixins/validation-mixin";
import ContactAddDialog from "@/view/components/ContactAddDialog";
// import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextValidateInput from "@/view/components/TextValidateInput.vue";
import MainMixin from "@/core/mixins/main.mixin.js";
import TagsAutoSearch from "@/view/components/TagsAutoSearch";
import objectPath from "object-path";
import TextAreaInput from "@/view/components/TextAreaInput";
import PhoneTemplate from "@/view/components/Phone";
import ManageDescriptionDialog from "@/view/pages/leads/components/ManageDescriptionDialog";
import SearchAddress from "@/view/components/Search-Address";
export default {
	mixins: [MainMixin],
	name: "Address",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		titleStatus: {
			type: String,
		},
		id: {
			type: Number,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			contactDialog: false,
			industrylist: [],
			contactlist: [],
			country_code: "sg",

			// contactlist: [
			// 	{ name: "John Doe", phone: "+65 12345678" },
			// 	{ name: "Johnson", phone: "+65 12345678" },
			// ],
			searchKey: +new Date(),
			typeId: 55,
			countryList: [
				{
					text: "Singapore",
					value: "singapore",
					country_code: "sg",
				},
				{
					text: "Malaysia",
					value: "malaysia",
					country_code: "my",
				},
				{
					text: "Myanmar",
					value: "myanmar",
					country_code: "mm",
				},
				{
					text: "India",
					value: "india",
					country_code: "in",
				},
				{
					text: "China",
					value: "china",
					country_code: "cn",
				},
				{
					text: "Australia",
					value: "australia",
					country_code: "at",
				},
				{
					text: "American",
					value: "american",
					country_code: "as",
				},
				{
					text: "UK",
					value: "uk",
					country_code: "gb",
				},
				{
					text: "Canada",
					value: "canada",
					country_code: "ca",
				},
			],
			company: {
				id: 0,
				company_name: null,
				email: null,
				phone: null,
				website: null,
				fax: null,
				contact: null,
				tags: [],
				address: null,
				company_uen: null,
				industry: null,
				isDescription: false,
				description: null,
				country: "singapore",
				address_line_1: null,
				address_line_2: null,
				unit_number: null,
				postal_code: null,
			},
		};
	},
	components: {
		TextInput,
		ContactAddDialog,
		// AutoCompleteInput,
		TextValidateInput,
		TagsAutoSearch,
		TextAreaInput,
		PhoneTemplate,
		ManageDescriptionDialog,
		SearchAddress,
	},
	methods: {
		getInitials(name) {

      const names = name.split(' ');
      if (names.length > 1) {
        return names.map(word => word[0].toUpperCase()).join('');
      } else {
        const firstLetter = names[0][0].toUpperCase();
        return firstLetter + names[0][1].toUpperCase();
      }
    },
		
		checkCompany(){
		

			// alert(this.company.)
			this.$store
				.dispatch(GET, {
					url: `check-exists-company/${this.company.company_name}`,
				})
				.then((response) => {
					console.log(response);
				})
				.catch((error) => {
					console.error("Error fetching contact:", error);
				});
		},
		countryCheck(data) {
			console.log(data, "data");

			/* To clear the field */
			this.searchKey = +new Date();

			let code = "";
			code = find(this.countryList, function (row) {
				if (row.value == data) {
					return row.country_code;
				} else {
					return false;
				}
			});
			console.log(code, "code");
			this.country_code = code?.country_code;
			this.company.postal_code = null;
			this.company.address_line_1 = null;
			this.company.unit_number = null;
			this.country = data;
			this.company.country = data;
			//console.log(this.country_code);
		},
		industryItem(param) {
			this.industrylist = param;
		},
		findCompany() {
			this.id = this.id;

			this.$store
				.dispatch(GET, {
					url: `find-single-company/${this.id}`,
				})
				.then((response) => {
					this.company = response?.customer; // Assuming data is in response.data
					this.company.tags = response?.customer?.tag;
					if (response?.customer?.companycontact) {
						this.company.contact = response.customer.contact.map((contact) => contact.id);
					}
					console.log(this.company.contact);
					console.log(this.company); // Logging the data received
				})
				.catch((error) => {
					console.error("Error fetching contact:", error);
				});
		},
		getContact() {
			if (this.id > 0) {
				this.$store
					.dispatch(QUERY, {
						url: `get-contact/${this.id}`,
						id: this.id,
					})
					.then((data) => {
						this.contactlist = data;
						console.log(data);
					})

					.catch((error) => {
						console.log({
							error,
						});
					});
			} else {
				this.$store
					.dispatch(GET, {
						url: `get-contact`,
					})
					.then((data) => {
						this.contactlist = data;
						console.log(data);
					})

					.catch((error) => {
						console.log({
							error,
						});
					});
			}
		},

		saveData() {
			this.pageLoading = true;
			console.log(this.company, "company");
			this.$store
				.dispatch(POST, { url: `create-company`, data: this.company })
				.then(({ data }) => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Company added successfully." },
					]);
					this.$refs.form.reset();
					this.$emit("createSuccess", data);

					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateData() {
			this.pageLoading = true;

			this.$store
				.dispatch(PUT, {
					url: `create-company/${this.id}`,
					data: this.company,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Company updated successfully." },
					]);
					this.$emit("createSuccess", true);
					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		update_or_create() {
			const formErrors = this.validateForm(this.$refs["form"]);

			this.$refs["form"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["form"].validate()) {
				return false;
			}
			if (this.id) {
				this.updateData();
			} else {
				this.saveData();
			}
		},
		contactAddDialog() {
			this.contactDialog = true;
		},
		// getSettings() {},
		getSetting() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `single-setting/${this.typeId}`,
				})
				.then((data) => {
					if (data && data.length) {
						this.industrylist = data;
					} else {
						this.industrylist = [
							{
								text: null,
							},
						];
					}
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		remove(item) {
			if (this.currentUser.role != 1 && this.currentUser.role != 4 && this.userType != "accounts") {
				return false;
			} else {
				const index = this.company.contact.indexOf(item.id);
				this.company.contact.splice(index, 1);
			}
		},
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		setAddress(e) {
			console.log({ e });
			console.log(e, "e");

			this.company.postal_code = e.postal_code;
			this.company.address_line_1 = e.address1 && e.address1 != "NIL" ? e.address1 : "";
			this.company.address_line_2 = e.address2 && e.address2 != "NIL" ? e.address2 : "";
			// this.customer.unit_number = e.unit_number;
		},
		appendDisplayName() {
			const result = [];
			const { first_name, last_name } = this.company;
			if (first_name) {
				result.push(first_name);
			}
			if (last_name) {
				result.push(last_name);
			}
			if (this.company.company_type == "personal") {
				this.company.display_name = result.join(" ");
			}

			/*    } */
			if (this.company.company_type == "company") {
				this.company.display_name = this.company.company_name;
			}
		},
		company_rules() {
			const rules = [];

			if (this.company.company_name) {
				rules.push(this.validateRules.required(this.company.company_name, "display name"));
			}

			// rules.push(this.validateRules.minLength(this.company.company_name, "display nam", 2));

			// rules.push(this.validateRules.maxLength(this.company.company_name, "display name", 100));

			return rules;
		},
	},
	mounted() {
		this.getContact();
		this.getSetting();
		if (this.id > 0) {
			this.findCompany();
		}
	},
};
</script>
<style>
aside.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--open.v-navigation-drawer--right.v-navigation-drawer--temporary.theme--light {
	z-index: 99 !important;
}
</style>
